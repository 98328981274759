<template>
    <!--begin::Container-->
        <div class="container">
            
        <!--begin::Dashboard-->
        
        <div class="row">
            <div class="col-xl-3">
                <!--begin::Stats Widget 29-->
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b" style="background-position: right top; background-size: 30% auto; background-image: url(/metronic/theme/html/demo12/dist/assets/media/svg/shapes/abstract-1.svg)">
                    <!--begin::Body-->
                    <div class="card-body">
                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                            <!--begin::Svg Icon | path:/metronic/theme/html/demo12/dist/assets/media/svg/icons/Communication/Mail-opened.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 Z" fill="#000000" opacity="0.3"/>
                                    <path d="M18.5,11 L5.5,11 C4.67157288,11 4,11.6715729 4,12.5 L4,13 L8.58578644,13 C8.85100293,13 9.10535684,13.1053568 9.29289322,13.2928932 L10.2928932,14.2928932 C10.7456461,14.7456461 11.3597108,15 12,15 C12.6402892,15 13.2543539,14.7456461 13.7071068,14.2928932 L14.7071068,13.2928932 C14.8946432,13.1053568 15.1489971,13 15.4142136,13 L20,13 L20,12.5 C20,11.6715729 19.3284271,11 18.5,11 Z" fill="#000000"/>
                                    <path d="M5.5,6 C4.67157288,6 4,6.67157288 4,7.5 L4,8 L20,8 L20,7.5 C20,6.67157288 19.3284271,6 18.5,6 L5.5,6 Z" fill="#000000"/>
                                </g>
                            </svg>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">$ {{stats != null ? stats.Counters.EarningsTotal.toFixed(2) : '0.00'}}</span>
                        <span class="font-weight-bold text-muted font-size-sm">Total Earned</span>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats Widget 29-->
            </div>
            <div class="col-xl-3">
                <!--begin::Stats Widget 29-->
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b" style="background-position: right top; background-size: 30% auto; background-image: url(/metronic/theme/html/demo12/dist/assets/media/svg/shapes/abstract-1.svg)">
                    <!--begin::Body-->
                    <div class="card-body">
                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                            <!--begin::Svg Icon | path:/metronic/theme/html/demo12/dist/assets/media/svg/icons/Communication/Mail-opened.svg-->
                            
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M16.0322024,5.68722152 L5.75790403,15.945742 C5.12139076,16.5812778 5.12059836,17.6124773 5.75613416,18.2489906 C5.75642891,18.2492858 5.75672377,18.2495809 5.75701875,18.2498759 L5.75701875,18.2498759 C6.39304347,18.8859006 7.42424328,18.8859006 8.060268,18.2498759 C8.06056298,18.2495809 8.06085784,18.2492858 8.0611526,18.2489906 L18.3196731,7.9746922 C18.9505124,7.34288268 18.9501191,6.31942463 18.3187946,5.68810005 L18.3187946,5.68810005 C17.68747,5.05677547 16.6640119,5.05638225 16.0322024,5.68722152 Z" fill="#000000" fill-rule="nonzero"/>
                                    <path d="M9.85714286,6.92857143 C9.85714286,8.54730513 8.5469533,9.85714286 6.93006028,9.85714286 C5.31316726,9.85714286 4,8.54730513 4,6.92857143 C4,5.30983773 5.31316726,4 6.93006028,4 C8.5469533,4 9.85714286,5.30983773 9.85714286,6.92857143 Z M20,17.0714286 C20,18.6901623 18.6898104,20 17.0729174,20 C15.4560244,20 14.1428571,18.6901623 14.1428571,17.0714286 C14.1428571,15.4497247 15.4560244,14.1428571 17.0729174,14.1428571 C18.6898104,14.1428571 20,15.4497247 20,17.0714286 Z" fill="#000000" opacity="0.3"/>
                                </g>
                                
                            </svg>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">{{stats != null ? stats.Counters.EarningsRatio.toFixed(2) : '0.00'}}%</span>
                        <span class="font-weight-bold text-muted font-size-sm">Earnings Ratio</span>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats Widget 29-->
            </div>
            <div class="col-xl-3">
                <!--begin::Stats Widget 29-->
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b" style="background-position: right top; background-size: 30% auto; background-image: url(/metronic/theme/html/demo12/dist/assets/media/svg/shapes/abstract-1.svg)">
                    <!--begin::Body-->
                    <div class="card-body">
                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                            <!--begin::Svg Icon | path:/metronic/theme/html/demo12/dist/assets/media/svg/icons/Communication/Mail-opened.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                 <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24"/>
                                    <rect fill="#000000" opacity="0.3" transform="translate(13.000000, 6.000000) rotate(-450.000000) translate(-13.000000, -6.000000) " x="12" y="8.8817842e-16" width="2" height="12" rx="1"/>
                                    <path d="M9.79289322,3.79289322 C10.1834175,3.40236893 10.8165825,3.40236893 11.2071068,3.79289322 C11.5976311,4.18341751 11.5976311,4.81658249 11.2071068,5.20710678 L8.20710678,8.20710678 C7.81658249,8.59763107 7.18341751,8.59763107 6.79289322,8.20710678 L3.79289322,5.20710678 C3.40236893,4.81658249 3.40236893,4.18341751 3.79289322,3.79289322 C4.18341751,3.40236893 4.81658249,3.40236893 5.20710678,3.79289322 L7.5,6.08578644 L9.79289322,3.79289322 Z" fill="#000000" fill-rule="nonzero" transform="translate(7.500000, 6.000000) rotate(-270.000000) translate(-7.500000, -6.000000) "/>
                                    <rect fill="#000000" opacity="0.3" transform="translate(11.000000, 18.000000) scale(1, -1) rotate(90.000000) translate(-11.000000, -18.000000) " x="10" y="12" width="2" height="12" rx="1"/>
                                    <path d="M18.7928932,15.7928932 C19.1834175,15.4023689 19.8165825,15.4023689 20.2071068,15.7928932 C20.5976311,16.1834175 20.5976311,16.8165825 20.2071068,17.2071068 L17.2071068,20.2071068 C16.8165825,20.5976311 16.1834175,20.5976311 15.7928932,20.2071068 L12.7928932,17.2071068 C12.4023689,16.8165825 12.4023689,16.1834175 12.7928932,15.7928932 C13.1834175,15.4023689 13.8165825,15.4023689 14.2071068,15.7928932 L16.5,18.0857864 L18.7928932,15.7928932 Z" fill="#000000" fill-rule="nonzero" transform="translate(16.500000, 18.000000) scale(1, -1) rotate(270.000000) translate(-16.500000, -18.000000) "/>
                                </g>
                            </svg>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">$ {{stats != null ? stats.Counters.TradedVolume.toFixed(2) : '0.00'}}</span>
                        <span class="font-weight-bold text-muted font-size-sm">Traded Volume (BUY/SELL)</span>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats Widget 29-->
            </div>
            <div class="col-xl-3">
                <!--begin::Stats Widget 29-->
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b" style="background-position: right top; background-size: 30% auto; background-image: url(/metronic/theme/html/demo12/dist/assets/media/svg/shapes/abstract-1.svg)">
                    <!--begin::Body-->
                    <div class="card-body">
                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                            <!--begin::Svg Icon | path:/metronic/theme/html/demo12/dist/assets/media/svg/icons/Communication/Mail-opened.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="13" rx="1.5"/>
                                    <rect fill="#000000" opacity="0.3" x="7" y="9" width="3" height="8" rx="1.5"/>
                                    <path d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z" fill="#000000" fill-rule="nonzero"/>
                                    <rect fill="#000000" opacity="0.3" x="17" y="11" width="3" height="6" rx="1.5"/>
                                </g>
                            </svg>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">{{stats != null ? stats.Counters.ProfitTrades.toFixed(0) : 0}} / {{stats != null ? stats.Counters.TotalTrades.toFixed(0) : 0}}</span>
                        <span class="font-weight-bold text-muted font-size-sm">Closed Trades / Total Trades</span>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats Widget 29-->
            </div>
            
        </div>

        <div class="row">
            <div class="col-lg-5 col-sm-12">
                <!--begin::Card-->
                <div class="card card-custom gutter-b" style="min-height:500px;">
                    <div class="card-header">
                        <div class="card-title">
                            <h3 class="card-label">Open positions</h3><br>
                    
                        </div>
                        
                    </div>
                    <span style="padding-left: 30px;" class="font-weight-bold text-muted font-size-sm">Amount of the positions currently open not yet closed. </span>
                    <div class="card-body" style="min-height: 416px;">
                        <!--begin::Chart-->
                        <div id="chart_12" class="d-flex justify-content-center"></div>
                        <!--end::Chart-->
                    </div>
                </div>
                <!--end::Card-->
            </div>
            <div class="col-lg-7 col-sm-12">
                <!--begin::Card-->
                <div class="card card-custom gutter-b" style="min-height:500px;">
                    <div class="card-header">
                        <div class="card-title">
                            <h3 class="card-label">History</h3>
                        </div>
                    </div>
                    <div class="card-body">
                        <!--begin::Chart-->
                        <div id="chart_5"></div>
                        <!--end::Chart-->
                    </div>
                </div>
                <!--end::Card-->
            </div>
        </div>

        

        

        
        
        <!--end::Dashboard-->
        </div>
        <!--end::Container-->
</template>

<script>
import Maurer from '../../components/Maurer.vue';
// Shared Colors Definition
const primary = '#00635C';
const success = '#027E75';
const info = '#11998F';
const warning = '#11998F';
const danger = '#38ef7d';
const apexChart = "#chart_12";
const apexChart2 = "#chart_5";
export default {

    watch: {
        stats(n, o){
            if(n != null){
                this.pieChart()
                this.historyChart(n.History)
            }
        },

        '$parent.pnl_period'(n, o){
            var vm = this;
            Maurer.api.user.stats({
                From: vm.$parent.pnl_period[0]._d.toISOString(),
                To: vm.$parent.pnl_period[1]._d.toISOString()
            },
            function (d) {
                vm.stats = d.data;
            }, function (d) {
                Maurer.api.error(d);
            });
        }
    },
    methods: {

        pieChart(){
            var vm = this;

            if(this.pieChartEl != null){
                this.pieChartEl.destroy()
            }
            
            var options = {
                series: Object.values(vm.stats.Investments),
                chart: {
                    height: 'auto',
                    type: 'pie',
                },
                stroke: {
                    show: true ,
                    colors: ['#2b333e']
                },
                legend: {
                    labels: {
                        colors: ['#ffffff']
                    },
                    position: 'bottom'
                },
                labels: Object.keys(vm.stats.Investments),
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 100
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                colors: [primary, success, warning, danger, info]
            };

            this.pieChartEl = new window.ApexCharts(document.querySelector(apexChart), options);
            this.pieChartEl.render();

        },

        historyChart(HistoryData){

            if(HistoryData == null){
                return;
            }
            
            if(this.historyChartEl != null){
                this.historyChartEl.destroy()
            }
            
            var options2 = {
                colors: [primary, danger, info],
                series: [{
                    name: 'Daily profit',
                    type: 'column',
                    data: HistoryData.map(function(a) {return a.DailyResult.toFixed(2);}).reverse()
                }],
                chart: {
                    height: 350,
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [1, 1, 4]
                },

                grid: {
                    borderColor: '#b5b5c3',
                },
                
                xaxis: {
                    categories: HistoryData.map(function(a) {return a.Date.substring(0,10);}).reverse(),
                    labels: {
                        style: {
                            colors: '#b5b5c3',
                        },
                        
                    },
                    
                    
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: primary
                        },
                        labels: {
                            style: {
                                colors: '#b5b5c3',
                            }
                        },
                        title: {
                            text: "USDT",
                            style: {
                                color: '#b5b5c3',
                            }
                        },
                        tooltip: {
                            enabled: true
                        }
                    },
                    
                    
                ],
                tooltip: {
                    fixed: {
                        enabled: true,
                        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                        offsetY: 30,
                        offsetX: 60
                    },
                    theme: 'dark',
                    followCursor: true
                },
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40,
                    labels: {
                        colors: ['#ffffff']
                    }
                },
            };

            this.historyChartEl = new window.ApexCharts(document.querySelector(apexChart2), options2);
            this.historyChartEl.render();
        },

        init(){
            var vm = this;

            Maurer.api.user.stats({
                From: new Date(Date.now()-(30*24*60*60*1000)),
                To: new Date(Date.now())
            },
            function (d) {
                vm.stats = d.data;
            }, function (d) {
                Maurer.api.error(d);
            });
            

        },

    },



    mounted(){
        var vm = this;
        vm.$parent.init();
        vm.init();
    },

    data(){
        return {
            stats: null,
            pieChartEl: null,
            historyChartEl: null
        }
    }
}
</script>

<style>
.disabled-div
{
  pointer-events: none;
  opacity: 0.5;
}


.card-label {
    color: #ffffff!important;
}

 .apexcharts-tooltip-title {
    background: #222831!important;
  }
  
</style>